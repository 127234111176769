var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('notification.sendNotification')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0 pt-1"
        }, [_c('b-form', [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('notification.subject'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "subject"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('notification.subject')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "subject",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('notification.subject')
                },
                model: {
                  value: _vm.notificationDataToAdd.subject,
                  callback: function callback($$v) {
                    _vm.$set(_vm.notificationDataToAdd, "subject", $$v);
                  },
                  expression: "notificationDataToAdd.subject"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('notification.content'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "content"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('notification.content')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "content",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "placeholder": _vm.$t('notification.content'),
                  "max-rows": "3"
                },
                model: {
                  value: _vm.notificationDataToAdd.content,
                  callback: function callback($$v) {
                    _vm.$set(_vm.notificationDataToAdd, "content", $$v);
                  },
                  expression: "notificationDataToAdd.content"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('notification.recipientRefType'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "recipientRefType",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('notification.recipientRefType')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.recipientRefTypeOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "disabled": "",
                  "input-id": "recipientRefType",
                  "placeholder": _vm.$t('notification.recipientRefType')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.notificationDataToAdd.recipientRefType,
                  callback: function callback($$v) {
                    _vm.$set(_vm.notificationDataToAdd, "recipientRefType", $$v);
                  },
                  expression: "notificationDataToAdd.recipientRefType"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('notification.channel'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "type",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('notification.channel')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.channelOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "disabled": "",
                  "input-id": "channel",
                  "placeholder": _vm.$t('notification.channel')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.notificationDataToAdd.channel,
                  callback: function callback($$v) {
                    _vm.$set(_vm.notificationDataToAdd, "channel", $$v);
                  },
                  expression: "notificationDataToAdd.channel"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('notification.sendType'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "sendType",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('notification.sendType')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.sendTypeOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "sendType",
                  "placeholder": _vm.$t('notification.sendType')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.notificationDataToAdd.sendType,
                  callback: function callback($$v) {
                    _vm.$set(_vm.notificationDataToAdd, "sendType", $$v);
                  },
                  expression: "notificationDataToAdd.sendType"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _vm.notificationDataToAdd.sendType === 'SCHEDULE' ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "sendTime"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('notification.sendTime')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "config": {
              minDate: 'today',
              allowInput: true,
              enableTime: true,
              dateFormat: 'Z',
              altFormat: 'H:i d-m-Y',
              altInput: true,
              locale: _vm.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
              disableMobile: true
            }
          },
          model: {
            value: _vm.notificationDataToAdd.sendTime,
            callback: function callback($$v) {
              _vm.$set(_vm.notificationDataToAdd, "sendTime", $$v);
            },
            expression: "notificationDataToAdd.sendTime"
          }
        })], 1)], 1) : _vm._e(), _vm.notificationDataToAdd.sendType === 'SCHEDULE' ? _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-form-group', [_c('BFormCheckbox', {
          model: {
            value: _vm.sendOneTime,
            callback: function callback($$v) {
              _vm.sendOneTime = $$v;
            },
            expression: "sendOneTime"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('notification.sendOneTime')) + " ")])], 1)], 1) : _vm._e(), _vm.notificationDataToAdd.sendType === 'SCHEDULE' && !_vm.sendOneTime ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "reschedulePeriodInHour"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('notification.reschedulePeriodInSecond')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "d-flex-center gap-2"
        }, [_c('validation-provider', {
          staticClass: "d-flex-center gap-2 flex-1",
          attrs: {
            "rules": "required|min_value:0"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars"
                }],
                staticClass: "flex-1",
                attrs: {
                  "id": "hoursReschedulePeriod",
                  "type": "number",
                  "min": "0",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.hoursReschedulePeriod,
                  callback: function callback($$v) {
                    _vm.hoursReschedulePeriod = $$v;
                  },
                  expression: "hoursReschedulePeriod"
                }
              }), _vm._v(" " + _vm._s(_vm.$t('notification.hour')) + " ")];
            }
          }], null, true)
        }), _c('validation-provider', {
          staticClass: "d-flex-center gap-2 flex-1",
          attrs: {
            "rules": "required|min_value:0|maxValue:59"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars"
                }],
                staticClass: "flex-1",
                attrs: {
                  "id": "minutesReschedulePeriod",
                  "type": "number",
                  "max": "59",
                  "min": "0",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.minutesReschedulePeriod,
                  callback: function callback($$v) {
                    _vm.minutesReschedulePeriod = _vm._n($$v);
                  },
                  expression: "minutesReschedulePeriod"
                }
              }), _vm._v(" " + _vm._s(_vm.$t('notification.minute')) + " ")];
            }
          }], null, true)
        })], 1)])], 1) : _vm._e(), _vm.notificationDataToAdd.sendType === 'SCHEDULE' && !_vm.sendOneTime ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "deadline"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('notification.deadline')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "config": {
              minDate: 'today',
              allowInput: true,
              enableTime: true,
              dateFormat: 'Z',
              altFormat: 'H:i d-m-Y',
              altInput: true,
              locale: _vm.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
              disableMobile: true
            }
          },
          model: {
            value: _vm.notificationDataToAdd.deadline,
            callback: function callback($$v) {
              _vm.$set(_vm.notificationDataToAdd, "deadline", $$v);
            },
            expression: "notificationDataToAdd.deadline"
          }
        })], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex mt-1 justify-content-center"
        }, [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": _vm.backHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), _c('b-button', {
          staticClass: "mx-1",
          attrs: {
            "variant": "danger",
            "disabled": pristine
          },
          on: {
            "click": _vm.clearHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('employee.clear')) + " ")]), _c('b-button', {
          attrs: {
            "variant": "info",
            "disabled": invalid
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.submitHandle);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('next')) + " ")])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }